<template>
    <Layout>
        <b-card>
            <b-card-header>
                <b-card-title class="h2">
                    Agenda
                </b-card-title>
            </b-card-header>
            <div class="card-header p-0 nav">
                <div class="row no-gutters" role="tablist">
                    <div class="col-auto">
                        <div
                            @click="view = 'availability'"
                            class="dashboard-area-tabs__tab card-body d-flex flex-row align-items-center justify-content-start pointer"
                            :class="view === 'availability' ? 'active' : ''"
                        >
                                    <span class="flex d-flex flex-column">
                                        <strong>Disponibilidades</strong>
                                    </span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div
                            @click="view = 'calendar'"
                            class="dashboard-area-tabs__tab card-body d-flex flex-row align-items-center justify-content-start pointer"
                            :class="view === 'calendar' ? 'active' : ''"
                        >
                                    <span class="flex d-flex flex-column">
                                        <strong>Calendário</strong>
                                    </span>
                        </div>
                    </div>
                </div>
            </div>
            <b-card-body v-show="view === 'availability'">

                <BRow>
                    <BCol class="text-center">
                        <BBadge variant="info" class="w-100 p-3 fs-14 mb-3">
                            Selecione uma mentoria e clique ou arraste o mouse sobre os horários
                            para selecionar os horários disponíveis
                        </BBadge>
                    </BCol>
                </BRow>

                <Schedule/>

            </b-card-body>

            <b-card-body  v-if="view === 'calendar'">

                <FullCalendar/>

            </b-card-body>
        </b-card>
    </Layout>
</template>

<script>
import Layout from '@/components/layout/main.vue';
import Schedule from "./schedule.vue";
import FullCalendar from "./full-calendar-component.vue";

export default {
    components: {
        Layout,
        Schedule,
        FullCalendar
    },

    data() {
        return {
            view: 'availability',
        }
    },
}
</script>

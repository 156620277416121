/**
 * Função que converte json e FormData
 * @param formData json com campos do formulário
 * @return FormData
 */
export function toFormData(formData) {
    const data = new FormData();

    // Adicionar automaticamente todas as propriedades de this.formData
    Object.keys(formData).forEach(key => {

        if (!Array.isArray(formData[key])) {
            if (formData[key] != null) {
                data.append(key, formData[key]);
            }
        }

    });

    return data;
}

/**
 * Função de validação de Formulários
 * @param Vue instância vue
 * @param data objeto com os campos enviados via post
 * @param required array com os campos obrigratórios do formulário
 * @return {boolean}
 */
export function ValidateForm(Vue, data, required) {
    let value = true;
    const keys = Object.keys(data);
    const keysReq = Object.keys(required);
    let errors = [];

    let ids = [];

    for (let i = 0; i < keysReq.length; i++) {
        if (keys.indexOf(keysReq[i]) > -1 && (!data[keysReq[i]] || data[keysReq[i]].length === 0)) {
            ids.push(keysReq[i]);
            errors.push(required[keysReq[i]]);
            value = false;
        }
    }

    if (errors.length > 0) {
        let result = 'Os campos ';
        if (errors.length === 1) {
            result = 'O campo ' + errors[0] + ' é obrigatório';
        } else if (errors.length === 2) {
            result += errors.join(' e ');
        } else {
            const lastTwo = errors.slice(-2).join(' e ');
            const rest = errors.slice(0, -2).join(', ');
            result += `${rest}, ${lastTwo}`;
        }

        if (errors.length >= 2) result += ' são obrigatórios';

        Vue.$notifyError(result);
    }

    console.log(ids);

    return value;
}

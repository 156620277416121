<template>
    <div id="table">
        <div class="d-flex mb-3 justify-content-between">
            <div class="d-flex">
                <div v-for="mentoring in mentorings" :key="`div-mentoring-${mentoring.id}`"
                     class="d-flex p-2 mr-2 legend"
                     @click="selectedId=mentoring.id; selectedColor=mentoring.color"
                     :style="selectedId === mentoring.id ? `border: 1.5px solid ${mentoring.color}!important;` : ''"
                >
                    <i class="chart-legend-indicator my-auto"
                       :style="`background-color: ${mentoring.color || '#FFF'}`"></i>
                    <span>{{ mentoring.title }}</span>
                </div>
            </div>
            <button class="btn btn-outline-danger" @click="resetSchedules">Resetar Disponibilidades</button>
        </div>
        <div class="overflow-auto">
            <BRow v-for="(day, i) in days" :key="'row-day-' + i">
                <BCol sm="2" class="mt-3">{{ day }}</BCol>
                <BCol sm="10">
                    <table class="table table-bordered" @mouseleave="isSelecting=false" style="user-select: none;">
                        <tr>
                            <th v-for="(hour, index) in hours" :key="index"
                                class="pointer"
                                @mousedown="startSelection(i + 1, index)"
                                @mouseover="continueSelection(i + 1, index)"
                                @mouseup="endSelection"
                                :style="getCellStyle(i + 1, index)">
                                {{ hour }}
                            </th>
                        </tr>
                    </table>
                </BCol>
            </BRow>
        </div>
        <BRow>
            <BCol class="text-end mt-3">
                <BButton variant="success" @click="saveSchedule" id="saveSchedule">Salvar</BButton>
            </BCol>
        </BRow>
    </div>
</template>

<script>
import http from "@/http"
import {endLoading, startLoading} from "@/components/composables/spinners";
import {messageSwal} from "@/components/composables/messages";

export default {

    data() {
        return {
            hours: [
                '06:00', '07:00',
                '08:00', '09:00', '10:00', '11:00', '12:00', '13:00',
                '14:00', '15:00', '16:00', '17:00', '18:00', '19:00',
                '20:00', '21:00', '22:00', '23:00'
            ],
            days: ['Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'],
            isSelecting: false,
            selectedCells: {
                1: [],
                2: [],
                3: [],
                4: [],
                5: [],
                6: [],
            },
            mentorings: [],
            selectedId: null,
            selectedColor: null
        }
    },

    methods: {
        async resetSchedules() {
            const result = await messageSwal(
                "Atenção!",
                "Tem certeza que deseja excluir todos os seus horários de disponibilidade? Isso não irá afetar agendas já confirmadas.",
                "warning"
            )
            if (result) {
                startLoading('table', 'saveSchedule');
                this.$store.commit('api/setSpinner', true);
                http.delete('mentor-schedules/all')
                    .then(response => {
                        this.selectedCells = {
                            1: [],
                            2: [],
                            3: [],
                            4: [],
                            5: [],
                            6: [],
                        }
                        this.$notifySuccess(response.data);
                    })
                    .catch(e => {
                        console.error(e);
                        this.$notifyError(e.response.data)
                    })
                    .finally(() => {
                        endLoading('table', 'saveSchedule');
                        this.$store.commit('api/setSpinner', false);
                    })
            }
        },

        startSelection(i, index) {
            if (this.selectedId && this.selectedColor) {
                this.isSelecting = true;
                this.toggleCellSelection(i, index);
            } else {
                this.$notifyError('Selecione uma mentoria!')
            }
        },

        continueSelection(i, index) {
            if (this.isSelecting && this.selectedId && this.selectedColor) {
                this.toggleCellSelection(i, index);
            }
        },

        endSelection() {
            this.isSelecting = false;
        },

        toggleCellSelection(i, index) {
            if (this.selectedId && this.selectedColor) {
                const hour = this.hours[index];
                const existing = this.selectedCells[i].find(cell => cell.hour === hour);

                if (existing) {
                    this.selectedCells[i] = this.selectedCells[i].filter(cell => cell.hour !== hour);
                } else {
                    this.selectedCells[i].push({
                        hour: hour,
                        mentoring_id: this.selectedId,
                        color: this.selectedColor
                    });
                }
            }
        },

        getCellStyle(i, index) {
            const hour = this.hours[index];
            const cell = this.selectedCells[i].find(cell => cell.hour === hour);

            return cell ? `background-color: ${cell.color}; color: white;` : '';
        },

        saveSchedule() {
            startLoading('table', 'saveSchedule');
            this.$store.commit('api/setSpinner', true);

            const allEmpty = Object.values(this.selectedCells).every(arr => arr.length === 0);

            if (allEmpty) {
                this.$notifyError('Você precisa selecionar ao menos um horário de disponibilidade!')
                endLoading('table', 'saveSchedule');
                this.$store.commit('api/setSpinner', false);
                return;
            }

            const data = {
                schedules: JSON.stringify(this.selectedCells),
            }

            http.post('mentor-schedules', data)
                .then(response => {
                    this.$notifySuccess(response.data);
                })
                .catch(e => {
                    console.error(e.response);
                    this.$notifyError(e);
                })
                .finally(() => {
                    endLoading('table', 'saveSchedule');
                    this.$store.commit('api/setSpinner', false);
                })
        },

        getData() {
            startLoading('table', 'saveSchedule');
            this.$store.commit('api/setSpinner', true);

            http.get('mentor-schedules')
                .then(response => {
                    response.data.forEach(element => {
                        this.selectedCells[element.day].push({
                            hour: element.hour,
                            mentoring_id: element.mentoring_id,
                            color: element.color
                        });
                    })
                })
                .catch(e => {
                    console.error(e.response);
                    this.$notifyError(e);
                })
                .finally(() => {
                    endLoading('table', 'saveSchedule');
                    this.$store.commit('api/setSpinner', false);
                })
        },

        getMentorings() {
            http.get('mentorings?status=1')
                .then(response => {
                    this.mentorings = response.data;
                })
        }

    },

    mounted() {
        this.getData();
        this.getMentorings();
    }
}
</script>

<style scoped>

.legend {
    border: 1px solid #E9EDF2;
    border-radius: 0.2rem;
}

.pointer {
    cursor: pointer;
}

</style>
